import cx from "classnames"
import { useEffect, useState } from "react"
import Helptip from "src/components/Helptip"
import styles from "./style.module.scss"

export default function Checkbox({
  readOnly = false,
  disabled = false,
  checked = false,
  onCheck = (checked) => {},
  className = null,
  label = null,
  tooltip = null,
  style = {}
}) {
  const [isChecked, setIsChecked] = useState(checked)

  useEffect(() => {
    setIsChecked(checked)
  }, [checked])

  const onCheckHandler = (event) => {
    if (disabled || readOnly || onCheck === false) return
    const r = onCheck(!isChecked, event)
    if (r !== false) {
      setIsChecked(!isChecked)
    }
  }

  const renderCheckbox = () => {
    return (
      <div
        style={{ ...style }}
        className={cx(
          styles.pseudoCheckbox,
          isChecked && styles.checked,
          disabled && styles.disabled,
          readOnly && styles.readOnly
        )}
      />
    )
  }

  return (
    <div className={cx(className, styles.wrapper, !disabled && !readOnly && styles.clickable)} onClick={onCheckHandler}>
      {label ? (
        <label className={styles.label} onClick={onCheckHandler}>
          {renderCheckbox()}
          {label}
          {tooltip && <Helptip name={tooltip} title={typeof label == "string" ? label : " "} />}
        </label>
      ) : (
        renderCheckbox()
      )}
    </div>
  )
}
