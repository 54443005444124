export default function Pill({ text = null, color = "red", height = 12, width = 6, style = {}, className = null }) {
  const renderPill = () => {
    return <div style={{ backgroundColor: color, height, width, borderRadius: 6, marginRight: 2, ...style }} />
  }

  return text ? (
    <div style={{ display: "flex", alignItems: "center", gap: 2 }} className={className}>
      {renderPill()}
      <span style={{ lineHeight: "1em", display: "inline-block" }}>{text}</span>
    </div>
  ) : (
    renderPill()
  )
}
