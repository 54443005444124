import authValidationMiddleware from "./authValidationMiddleware"
import axiosApiInstance from "./httpInterceptor"

export const getGenericSetting = authValidationMiddleware(async (settingName, empresa = null) => {
  const url = `/account/settings/${settingName}/`
  const params = {}
  if (empresa) {
    params.empresa = empresa
  }

  const response = await axiosApiInstance.get(url, { params })
  return response.data
})

export const getGlobalSetting = authValidationMiddleware(async (settingName) => {
  const url = `/settings/${settingName}/`

  const response = await axiosApiInstance.get(url)

  return response.data
})

export const getGeneralSettings = authValidationMiddleware(async (params = {}) => {
  const url = "/general_settings/"
  const response = await axiosApiInstance.get(url, { params })

  return response.data
})

export const saveGeneralSetting = authValidationMiddleware(async (setting, valor) => {
  const url = "/general_settings/"
  const response = await axiosApiInstance.post(url, { setting, valor })

  return response.data
})

export const getFrequencySettings = authValidationMiddleware(async (empresa = null) => {
  let url
  if (empresa) {
    url = `/account/settings/auditoria_tree_control_cant_validaciones_ranges/?empresa=${empresa}`
  } else {
    url = `/account/settings/auditoria_tree_control_cant_validaciones_ranges/`
  }

  const response = await axiosApiInstance.get(url)

  return response.data
})

export const getProbabilitySettings = authValidationMiddleware(async (empresa = null) => {
  const url = `/account/settings/auditoria_tree_riesgo_probabilidad_ranges/${empresa ? `?empresa=${empresa}` : ""}`

  const response = await axiosApiInstance.get(url)

  return response.data
})

export const getImpactSettings = authValidationMiddleware(async (empresa = null) => {
  const url = `/account/settings/auditoria_tree_riesgo_impacto_ranges/${empresa ? `?empresa=${empresa}` : ""}`
  const response = await axiosApiInstance.get(url)

  return response.data
})

export const getInherentRiskSettings = authValidationMiddleware(async (empresa = null) => {
  const url = `/account/settings/auditoria_tree_riesgo_ri_ranges/${empresa ? `?empresa=${empresa}` : ""}`

  const response = await axiosApiInstance.get(url)

  return response.data
})

export const getCriticalySettings = authValidationMiddleware(async (empresa = null) => {
  let url
  if (empresa) {
    url = `account/settings/auditoria_tree_criticidad_rotacion/?empresa=${empresa}`
  } else {
    url = `account/settings/auditoria_tree_criticidad_rotacion/`
  }

  const response = await axiosApiInstance.get(url)

  return response.data
})

export const updateGenericSetting = authValidationMiddleware(async (settingName, valor, empresa = null) => {
  const url = `/account/settings/${settingName}/`

  const params = {}
  if (empresa) {
    params.empresa = empresa
  }

  const response = await axiosApiInstance.post(url, { valor }, { params })
  return response.data
})

export const updateProbabilitySettings = authValidationMiddleware(async (payload = null, empresa = null) => {
  const url = `/account/settings/auditoria_tree_riesgo_probabilidad_ranges/${empresa ? `?empresa=${empresa}` : ""}`

  const requestConfig =
    payload !== null
      ? {
          valor: payload
        }
      : undefined

  const response = await axiosApiInstance.post(url, requestConfig)

  return response.data
})

export const updateImpactSettings = authValidationMiddleware(async (payload = null, empresa = null) => {
  const url = `/account/settings/auditoria_tree_riesgo_impacto_ranges/${empresa ? `?empresa=${empresa}` : ""}`

  const requestConfig =
    payload !== null
      ? {
          valor: payload
        }
      : undefined

  const response = await axiosApiInstance.post(url, requestConfig)
  return response.data
})

export const updateInherentRiskSettings = authValidationMiddleware(async (payload = null, empresa = null) => {
  const url = `/account/settings/auditoria_tree_riesgo_ri_ranges/${empresa ? `?empresa=${empresa}` : ""}`
  const requestConfig =
    payload !== null
      ? {
          valor: payload
        }
      : undefined

  const response = await axiosApiInstance.post(url, requestConfig)
  return response.data
})

export const updateCriticality = authValidationMiddleware(async (payload = null, empresa = null) => {
  const requestConfig =
    payload !== null
      ? {
          valor: payload
        }
      : undefined
  let url
  if (empresa) {
    url = `account/settings/auditoria_tree_criticidad_rotacion/?empresa=${empresa}`
  } else {
    url = `account/settings/auditoria_tree_criticidad_rotacion/`
  }

  const response = await axiosApiInstance.post(url, requestConfig)

  return response.data
})

export const deleteCriticality = authValidationMiddleware(async (empresa = null) => {
  let url
  if (empresa) {
    url = `account/settings/auditoria_tree_criticidad_rotacion/?empresa=${empresa}`
  } else {
    url = `account/settings/auditoria_tree_criticidad_rotacion/`
  }

  const response = await axiosApiInstance.delete(url)

  return response.data
})

export const getDesignControlEvaluationSettings = authValidationMiddleware(async (empresa = null) => {
  let url
  if (empresa) {
    url = `/account/settings/auditoria_tree_control_diseno_ranges/?empresa=${empresa}`
  } else {
    url = `/account/settings/auditoria_tree_control_diseno_ranges/`
  }

  const response = await axiosApiInstance.get(url)
  return response.data
})

export const updateDesignControlEvaluationSettings = authValidationMiddleware(
  async (payload = null, empresa = null) => {
    const requestConfig =
      payload !== null
        ? {
            valor: payload
          }
        : undefined

    let url
    if (empresa) {
      url = `/account/settings/auditoria_tree_control_diseno_ranges/?empresa=${empresa}`
    } else {
      url = `account/settings/auditoria_tree_control_diseno_ranges/`
    }
    const response = await axiosApiInstance.post(url, requestConfig)
    return response.data
  }
)

export const deleteDesignControlEvaluationSettings = authValidationMiddleware(async (empresa = null) => {
  let url
  if (empresa) {
    url = `account/settings/auditoria_tree_control_diseno_ranges/?empresa=${empresa}`
  }
  const response = await axiosApiInstance.delete(url)

  return response.data
})

export const updateCurrencyFormat = authValidationMiddleware(async (valor, empresa = null) => {
  const url = `/account/settings/user_l10n_currency_format/`

  const params = {}
  if (empresa) {
    params.empresa = empresa
  }

  const response = await axiosApiInstance.post(url, { valor }, { params })

  return response.data
})

export const deleteGeneralSettings = authValidationMiddleware(async (settingName, empresa = null) => {
  const url = `/account/settings/${settingName}/`

  const params = {}
  if (empresa) {
    params.empresa = empresa
  }

  const response = await axiosApiInstance.delete(url, { params })
  return response.data
})
